<template>
    <div class="pb-card">
        <div
            class="pb-card__foto-container"
            @click="handleOpenFotos"
            :style="{ cursor: user.aantal_extra_fotos > 0 ? 'pointer' : 'auto' }"
        >
            <img :src="user.foto" alt="Profielfoto niet gevonden" class="pb-card__foto" />
            <span class="fototeller" v-if="user.aantal_extra_fotos && user.aantal_extra_fotos > 0">
                Klik hier voor meer foto's
            </span>
        </div>
        <div class="pb-card__tekst">
            {{ kortePBTekst }}...
            <div class="pb-card__buttons">
                <button class="btn" @click="$router.push('mijn-beschrijving')">Naar mijn beschrijving</button>
                <button class="btn" @click="$router.push('mijn-beschrijving/edit')">Wijzig beschrijving</button>
                <button class="btn" @click="$router.push('mijn-beschrijving/foto')">Wijzig foto</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['user']),
            kortePBTekst() {
                let tekst = this.user.persoonsbechrijving.PB.substr(0, 600);
                return tekst;
            },
        },
        methods: {
            ...mapActions(['setFotos']),
            handleOpenFotos() {
                this.setFotos({
                    foto: this.user.foto,
                    extra_fotos: this.user.extra_fotos || null,
                });
            },
        },
    };
</script>
