<template>
    <div>
        <FullscreenLoader v-if="!user" />
        <template v-else>
            <NieuwsBerichten />

            <BemiddelaarCard v-if="voorstellen.length > 1 || naamBemiddelaar === 'Matchmaker'" />
            <BemiddelaarWelkom v-else />

            <h1 class="oleo">Overzicht</h1>

            <!-- GEGEVENS TABEL -->
            <div class="header-bar">Mijn gegevens</div>
            <table class="mijnps-table">
                <tr>
                    <td>
                        <strong>Naam:</strong>
                    </td>
                    <td>{{ user.Voornaam }} {{ user.achternaam }}</td>
                </tr>
                <tr>
                    <td>
                        <strong>E-mailadres:</strong>
                    </td>
                    <td>{{ user.Email }}</td>
                </tr>
            </table>

            <!-- BERICHTEN TABEL -->
            <div class="header-bar header-bar--m0">Openstaande berichten ({{ ongelezenBerichten.length }})</div>
            <template v-if="ongelezenBerichten.length > 0">
                <table class="mijnps-table">
                    <thead>
                        <tr>
                            <th>Afzender</th>
                            <th>Onderwerp</th>
                            <th>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="bericht in ongelezenBerichten"
                            :style="{ fontWeight: bericht.gelezen ? 'normal' : 'bold' }"
                            :key="bericht.Nummer"
                            class="table-clickable"
                            @click="$router.push(`/berichten/${bericht.Nummer}`)"
                        >
                            <td>{{ bericht.Afzender }}</td>
                            <td>{{ bericht.Onderwerp }}</td>
                            <td>{{ toonKorteDatum(bericht.DatumTijd) }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <p style="text-align: center; margin-bottom: 4rem">
                    Je hebt geen ongelezen berichten. Klik
                    <router-link to="/berichten">hier</router-link> om naar alle berichten te gaan.
                </p>
            </template>

            <!-- VOORSTELLEN TABEL -->
            <div class="header-bar header-bar--m0">Openstaande voorstellen ({{ openstaandeVoorstellen.length }})</div>
            <template v-if="openstaandeVoorstellen.length > 0">
                <div class="voorstel-cards voorstel-cards--home">
                    <Card
                        v-for="voorstel in openstaandeVoorstellen"
                        :key="voorstel.Voorstelnummer"
                        :openstaand="voorstel.Openstaand"
                        :voornaam="voorstel.Voornaam"
                        :profielFoto="voorstel.foto_p2"
                        :datum="voorstel.DatumA"
                        :voorstelNummer="voorstel.Voorstelnummer"
                        :fotocode="voorstel.Foto_code"
                    />
                </div>
            </template>
            <template v-else>
                <p style="text-align: center; margin-bottom: 4rem">
                    Er zijn geen openstaande voorstellen. Klik
                    <router-link to="/voorstellen">hier</router-link> voor het overzicht van alle voorstellen.
                </p>
            </template>

            <!-- CARD -->
            <div class="header-bar header-bar--m0">Mijn beschrijving</div>
            <PBCard />
        </template>
    </div>
</template>

<script>
    import { toonKorteDatum, toonLangeDatum, toonVoorstelDatum } from '../functions/toonDatum';
    import { mapGetters, mapActions } from 'vuex';
    // import ErrorMessage from '../components/ErrorMessage';
    import NieuwsBerichten from '../components/NieuwsBericht';
    import { errorToMessage } from '../functions/errorHandler';
    import BemiddelaarCard from '../components/BemiddelaarCard';
    import PBCard from '../components/PBCard';
    import BemiddelaarWelkom from '../components/BemiddelaarWelkom';
    import Card from '../components/Card.vue';

    export default {
        data() {
            return {
                ww1: null,
                ww2: null,
                formError: false,
                nieuwMailadres: '',
                nieuwMailadresErr: false,
            };
        },
        mounted() {
            scrollTo(0, 0);
        },
        methods: {
            ...mapActions(['addNotification', 'changePassword']),
            doNothing() {
                return;
            },
            toonLangeDatum: toonLangeDatum,
            toonKorteDatum: toonKorteDatum,
            toonVoorstelDatum,
            wijzigWachtwoord() {
                this.formError = false;
                if (!this.ww1 || !this.ww2) {
                    this.$store.commit('SET_ERROR', 'Vul beide velden in om het wachtwoord te wijzigen');
                    this.formError = true;
                    return;
                }

                if (this.ww1 != this.ww2) {
                    this.$store.commit('SET_ERROR', 'De ingevulde wachtwoorden komen niet overeen.');
                    this.formError = true;
                    return;
                }
                if (Object.values(this.wwRequirements).includes(false)) {
                    this.formError = true;
                    this.addNotification('Het wachtwoord voldoet niet aan de vereisten');
                    return;
                }

                this.$store.commit('CLEAR_ERROR');
                this.changePassword(this.ww1)
                    .then(() => {
                        this.formError = false;
                        this.ww1 = '';
                        this.ww2 = '';
                    })
                    .catch((err) => {
                        const msg = errorToMessage(err);
                        console.error(msg);
                        this.$store.commit('SET_ERROR', msg);
                    });
            },
        },
        computed: {
            ...mapGetters(['user', 'ontvangenBerichten', 'voorstellen', 'naamBemiddelaar']),
            ongelezenBerichten: function () {
                return this.ontvangenBerichten.filter((e) => e.Gelezen === '0');
            },
            openstaandeVoorstellen: function () {
                return this.voorstellen.filter((e) => e.Openstaand === '1');
            },
            wwRequirements() {
                return {
                    isLong: this.ww1.length >= 6,
                    hasUppercase: this.ww1.match(/[A-Z]/) || false,
                    hasLowercase: this.ww1.match(/[a-z]/) || false,
                    hasNumber: this.ww1.match(/[0-9]/) || false,
                };
            },
        },
        components: {
            // ErrorMessage,
            NieuwsBerichten,
            BemiddelaarCard,
            PBCard,
            BemiddelaarWelkom,
            Card,
        },
    };
</script>

<style>
    .ww_req_icon__valid::after {
        font-weight: 900;
        content: '\2713';
        color: green;
    }
</style>
